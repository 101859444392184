.successDiv {
	height: 200px;
	padding: 10px;
	background: transparent;
	width: 80%;
	margin: auto;
	z-index: 1001;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
	border-radius: 30px;
	min-width: 300px;
	font-size: 12pt;
}

.errorDiv {
	z-index: 1000;
	height: 200px;
	padding: 20px 10px;
	background: transparent;
	color: red;
	margin: auto;
	width: 80%;
	font-size: 12pt;
	min-width: 300px;
	font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
		"Lucida Sans", Arial, sans-serif;
}
