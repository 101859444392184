.importButton {

    margin:10px;
    background:#f92;
    color:white;
    min-width:150px;
    min-height:50px;
    border-radius: 40px;
    padding:10px;
    font-weight: 600;
}

.importButtonSmall {

    margin:10px;
    background:#f92;
    color:white;
    min-width:100px;
    min-height:30px;
    border-radius: 30px;
    padding:4px 10px 10px 10px;
    font-weight: 600;
}


#stake-btn{
    position: relative;
    top:0.7vw;
}

.stakingForm {

    padding:20px;
    margin:auto;
    max-width: 700px;
    color:black;
    text-align: justify;
    display: flex;
    align-items: stretch;

}


@media only screen and (max-device-width: 768px){

    .nftList {
        min-width:50vw;
        margin:auto;
        padding:20px;
        text-align: justify;
        width: 100%;
        display: table;
    }
}

@media only screen and (min-device-width: 769px){
    .nftList {
        min-width:50vw;
        margin:auto;
        padding:20px;
        text-align: justify;
        width: 100%;
        display: table;
    }
}

.nftRow {
    padding:10px;
    text-align: left;
    display: table-row;
    width:100%;
    margin:10px;
}

@media screen and (max-width: 600px) {
    .type-class{
    display: none;
}
}

@media screen and (min-width: 600px) {
  
.nftRow .col {

    display: table-cell;  
}
}


.importTokenForm{

    color:white;
}